.navbar {
  position: fixed;
  top: 45px; 
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 8px 12px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

/* Brand */
.brand {
  display: flex;
  width: min-content;
  align-items: center;
}

.sidebar-toggle-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.sidebar {
  position: fixed;
  top:106px; /* Adjusted position */
  left: -300px; /* Hide the sidebar by default */
  width: 300px; /* Adjusted width */
  height: calc(100vh - 45px); /* Adjusted height */
  background-color: #333;
  transition: left 0.3s ease;
}

.button-simple {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the icon vertically */
}

.icon-bar {
  display: block;
  border: 0.5px solid #fff;
  margin: 3px auto;
}
.menu-header {
  margin-right: 40px;
  justify-items: center;
  align-items: center;
}
.btn-container{
  width: 100%;
  align-content: center;
  justify-content: center;
}
.header-menu-and-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.labelmeny {
  width: 50px;
  text-align: center;
  padding-top: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  vertical-align: middle;
}
.sidebar.open {
  left: 0; /* Show the sidebar when it's open */
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  color: #fff;
  border-bottom: 1px solid #555;
}
.sidebar li span{
  cursor: pointer;
  color: #fff;
}

.sidebar li:last-child {
  border-bottom: none;
}

.logo {
  width: 40px; /* Adjusted logo size */
  margin-right: 6px; /* Adjusted margin */
}

.site-name {
  text-decoration: none;
  color: #fff;
  font-size: 18px; 
  font-weight: bold;
  min-width: 200px;
  margin: 0;
}

/* Links */
.links {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.links.open {
  flex-direction: column;
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 12px 0; /* Adjusted padding */
  z-index: 1;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px; /* Adjusted font size */
  margin-right: 8px; /* Adjusted margin */
  transition: color 0.3s ease;
}

/* Search Input */
.search-input-container {
  position: relative;
  padding: 6px;
  width: 30%;
}

.search-input {
  padding-top: 24px;
  padding-right: 24px; /* Adjusted padding */
  border-radius: 15px; /* Adjusted border radius */
  border: 1px solid rgba(223, 193, 193, 0.5);
  background-color: rgba(51, 51, 51, 0.7);
  color: #fff;
  outline: none;
  width: 100%; /* Adjusted width */
  overflow: hidden;
  padding: 8px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjusted position */
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

/* Dropdown */
.dropdown {
  padding-right: 20px;
  padding-left: 8px;
}

.category-dropdown {
  padding: 6px 8px; /* Adjusted padding */
  font-size: 12px; /* Adjusted font size */
  border-radius: 4px; /* Adjusted border radius */
  background-color: #555;
  color: #fff;
  cursor: pointer;
}

.category-dropdown:hover {
  background-color: #444;
}

.category-dropdown:focus {
  outline: none;
}

/* Hamburger Menu */
.hamburger {
  display: none;
}

/* Hover effect for icons */
.nav-link:hover {
  color: #dff745; /* Change color on hover */
}

@media screen and (max-width: 750px) {
  .links {
    display: none;
  }

  .links.open {
    display: flex;
  }

  .hamburger {
    display: table-row;
    position: absolute;
    top: 8px; /* Adjusted position */
    right: 8px; /* Adjusted position */
    cursor: pointer;
  }

  .bar {
    width: 20px; /* Adjusted size */
    height: 2px; /* Adjusted size */
    background-color: #fff;
    margin: 3px 0; /* Adjusted margin */
    transition: 0.4s;
  }

  .open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-4px, 4px); /* Adjusted position */
  }

  .open .bar:nth-child(2) {
    opacity: 0;
  }

  .open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-3px, -3px); /* Adjusted position */
  }

  .links {
    display: none;
  }
}
