.shop {
  padding: 20px;
}

.shopTitle {
  margin-top: 50px;
  text-align: center;
  font-size: 40px;
}

.products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

.product {
  border-radius: 15px;
  width: 280px; /* Adjust this width to your preference */
  height: auto; /* Let the height adjust automatically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product img {
  width: 100%; /* Make the image full-width */
  height: auto; /* Let the height adjust automatically */
  border-radius: 10px;
}

.product .description {
  text-align: center;
  margin-top: 10px;
}

.addToCartBttn {
  background-color: transparent;
  border: 2px solid rgb(19, 19, 19);
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 15px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.addToCartBttn:hover {
  background-color: rgb(19, 19, 19);
  color: white;
}

.image-container {
  width: 100%; /* Set the width of the container */
  height: auto; /* Let the height adjust automatically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.product-image {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  max-height: 100%; /* Ensure the image doesn't exceed its container */
  border-radius: 10px;
}
.slider-container {
  width: 80%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the slider */
}

.slick-slide img {
  max-width: 150%; /* Ensure the image fits within the container */
  max-height: 100%; /* Ensure the image fits within the container */
  border-radius: 10px; /* Apply border radius to the images */
}
/* Hide text and add icons for slider navigation buttons */
/* Hide text for slider navigation buttons */
.slick-prev,
.slick-next {
  font-size: 0; /* Hide text */
}

/* Add icons for slider navigation buttons */
.slick-prev::before,
.slick-next::before {
  content: '\2190'; /* Unicode for left arrow */
  font-size: 24px;
}

.slick-next::before {
  content: '\2192'; /* Unicode for right arrow */
}

/* Update styles for slider navigation buttons */
.slick-prev,
.slick-next {
  background-color: transparent;
  border: 2px solid #007bff;
  color: #007bff;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Update styles for hovered state */
.slick-prev:hover,
.slick-next:hover {
  background-color: #007bff;
  color: white;
}

/* Update styles for slider dots */
.slick-dots {
  bottom: 20px;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #007bff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Style for hovered state */
.slick-dots li button:hover {
  background-color: #007bff;
}
