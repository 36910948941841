/* Base styles */
.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 20px; /* Adjust padding as needed */
  max-height: 90vh; /* Limit the maximum height to 70% of the viewport height */
  overflow-y: auto; /* Add vertical scroll if content overflows */
}
.product-table {
  width: 100% !important; /* Ensure that the width is applied */
  border-collapse: collapse;
}

.product-table th,
.product-table td {
  border: 1px solid #eeeeee;
  padding: 20px; /* Increase padding */
  text-align: center;
  font-size: 16px; /* Increase font size */
}

.product-table th {
  background-color: #fffafa;
}

.product-table input[type="number"] {
  width: 50px; /* Adjust width for better usability */
  padding: 8px;
}


.cart-actions {
  width: 150px;
  max-width: 100%; /* Limit maximum width */
}

.checkout button {
  width: 150px;
  height: 50px;
  background-color: rgb(19, 19, 19);
  color: white;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;

}

.empty-cart-message {
  margin-top: 20px;
  text-align: center;
}
