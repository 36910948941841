.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20vh;
  width: 100%;
}

.card {
  width: 70%;
  max-width: 100%;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.red-icon {
  color: red;
}

.card-header {
  background-color: #dff745;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #000;
  text-align: center;
  padding: 10px;
}

.card-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.group-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-container {
  width: 48%; /* Adjusted width */
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.input-container label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-container input[type="text"],
.input-container input[type="email"],
.input-container input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

button[type="submit"] {
  width: 100px;
  padding: 12px;
  background-color: #0f7c00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #0c6400;
}

.product-table {
  border-collapse: collapse;
  width: 100%;
}

.product-table th,
.product-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.product-table td div { 
  display: flex;
  align-items: center; 
  gap: 10px;
}

.contact-image-container { align-self: center; }
.product-table th {
  background-color: #f2f2f2;
}

.product-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.product-table tr:hover {
  background-color: #ddd;
}
.contact-image-container {
  width: 50px;
  height: 50px;
  background-color: black;
}

