.social-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 11px 20px;
  display: flex;
  justify-content: space-between;
  z-index: 1100;
}

.left-content {
  display: flex;
  align-items: center;
}

.email {
  color: white;
  font-size: 24px;
}

.email-address {
  color: white;
  text-decoration: none;
  font-size: 16px; /* Adjust font size as needed */
  margin-left: 5px; /* Add margin between icon and email address */
}

.social-icons {
  display: flex;
  padding-right: 20px;
}

.social-icon {
  color: white;
  font-size: 24px;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #beda08;
}
