footer {
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
  padding-top: 50px; /* Adjust the padding-top value as needed */
}

.content {
  margin-top: 50px; /* Adjust the value as needed */
}

.contact-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.contact-item svg {
  margin-right: 5px;
}

.contact-link {
  text-decoration: none;
  color: white;
}

.footer-content {
  display: flex;
  align-items: center;
  flex: 1; /* Added to allow the description to take available space */
}

.description {
  text-align: center; /* Centering the description */
  font-size: 14px;
  flex: 1; /* Added to take available space */
}

.social-media {
  display: flex;
}

.social-media a {
  color: white;
  text-decoration: none;
  margin-right: 15px;
}

.social-media a:last-child {
  margin-right: 0;
}

.social-media a:hover {
  opacity: 0.7;
}
